// NIH: person.less
// Rewritten for new base template Sep. 2024 by Kjetil

#vrtx-uh-person {
    #vrtx-person-top-area {
        border: 1px solid var(--gr);
        @media (@screen-medium) {
            border-width: 1px 0;
            padding: 40px 0;
        }
        @media (@screen-below-medium) {
            border-width: 0 0 1px 0;
            padding-bottom: 80px;
        }
        h1 {
            font-size: 3.6rem;
            margin-bottom: 15px;
            @media (@screen-medium) {
                grid-column: 2;
            }
        }
        img {
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 100%;
            @media (@screen-medium) {
                grid-row: 1/5;
            }
            @media (@screen-below-medium) {
                order: -1;
            }
        }
        .vrtx-position {
            margin-bottom: 10px;
        }
        .vrtx-person-contact-info-list {
            display: grid;
            grid-template-columns: auto auto;
            width: fit-content;
            gap: 4px 16px;
            margin-bottom: 30px;
            dt {
                font-weight: 600;
            }
            dd {
                margin: 0;
            }
        }
        #vrtx-person-vcard {
            display: none;
        }
    }
    // Elements after top area
    #vrtx-publications-wrapper {
        max-width: var(--width-text-max);
    }
    #vrtx-publication-tabs {
        li {
            padding: 0;
        }
    }
}