// NIH: article.less
.vrtx-article-body {
    max-width: var(--width-text-max);
    border-bottom: none;
}

#vrtx-structured-article {
    .vrtx-introduction {
        margin-bottom: 30px;
    }
    .vrtx-authors, .vrtx-date-info {
        display: inline-block;
        margin-bottom: 40px;
    }
    .vrtx-authors .vrtx-authors-prefix, .vrtx-date-info .published-date-label {
        font-weight: normal;
        &::after {
            content: "";
        }
    }
    .vrtx-authors::after {
        content: "/";
    }
    .vrtx-date-info {
        .last-modified-date {
            display: none;
        }
        .separator-date {
            display: none;
        }
    }
    .vrtx-social-components {
        a.vrtx-email-friend::before {
            background-image: url(../images/social/mail.svg);
        }
        a.facebook::before {
            background-image: url(../images/social/facebook.svg);
        }
        a.twitter::before {
            background-image: url(../images/social/x.svg);
        }
    }
    .vrtx-introduction-image {
        position: relative;
        max-width: 1041px;
    }
}
