// NIH: frontpage.less

@import 'variables.less';

#vrtx-frontpage {
    #main {
        .grid-container, .grid-container.row-all-colored {
            margin-bottom: calc(3 * var(--space));
            @media (@screen-below-medium) {
                margin-bottom: calc(2 * var(--space));
            }
        }
    }
    // If box has vrtx-more remove link decoration
    .vrtx-frontpage-box:has(.vrtx-more) h2 a {
        color: var(--color-dark);
        text-decoration: none;
    }
    #vrtx-content {
        max-width: var(--width-total-max);
        margin: 0 auto;
    }
    #vrtx-frontpage-introduction .vrtx-introduction,  h1 {
        max-width: var(--width-total);
        margin-left: 0;
    }

    .vrtx-frontpage-row.row .img-special-right:not(.red):not(.grey) {
        .vrtx-box-content {
            padding: 10% 10% 10% 0;
        }
    }
    @media (@screen-below-medium) {
        .img-special-left, .img-special-right {
            .vrtx-box-content {
                padding: 30px 0 0;
            }
            &.red, &.grey {
                .vrtx-box-content {
                    padding: 30px 15px 0;
                }
            }
        }
    }

    .row-all-colored {
        .vrtx-frontpage-box.box-special {
            background-color: var(--color-neutral-tint-95);
        }
    }
    .img-special-left, .img-special-right, .img-special-left-large, .img-special-right-large {
        background: var(--color-light);
        position: relative;
        overflow: hidden;
        img {
            aspect-ratio: 4/3;
        }
        &.diagonal .vrtx-box-content {
            z-index: 1;
            &:after {
                z-index: -1;
                display: none;
                @media (@screen-medium) {
                    display: block;
                    content: '';
                    position: absolute;
                    height: 110%;
                    width: 15%;
                    top: 0;
                    background: var(--color-light);
                }
            }
        }
        &.red {
            background: var(--color-primary);
            .vrtx-box-content {
                color: var(--color-light);
                ul, ol {
                    margin-bottom: 13px;
                    li::marker {
                        color: var(--color-light);
                    }
                }
                a {
                    color: var(--color-light);
                    display: flex;
                    align-items: center;
                    padding-right: 0.5em;
                    &.vrtx-more, &.read-more {
                        &::after {
                            content: "";
                            background-image: url('../images/arrow-white.svg');
                            background-repeat: no-repeat;
                            position: relative;
                            right: -0.5em;
                            top: 1px;
                            width: 0.9em;
                            height: 0.9em;
                        }
                        &:hover::after {
                            right: -1em;
                        }
                    }
                }
            }
            &.diagonal .vrtx-box-content {
                &:after {
                    display: none;
                    @media (@screen-medium) {
                        display: block;
                        background: var(--color-primary);
                    }
                }
            }
        }
        &.grey {
            background: var(--color-neutral--light);
            &.diagonal .vrtx-box-content:after {
                display: none;
                @media (@screen-medium) {
                    display: block;
                    background: var(--color-neutral--light);
                }
            }
        }
    }
    .img-special-left-large, .img-special-right-large {
        .vrtx-box-content {
            font-size: 2.2rem;
        }
        img {
            aspect-ratio: 1/1;
        }
    }
    .img-special-left.diagonal .vrtx-box-content:after {
        display: none;
        @media (@screen-medium) {
            transform: rotate(16deg);
            left: 50%;
            transform-origin: left top;
        }
    }
    .img-special-right.diagonal .vrtx-box-content:after {
        @media (@screen-medium) {
            display: block;
            transform: rotate(-16deg);
            right: 50%;
            transform-origin: right top;
        }
    }
    
    // frontpage row
    .grid-container {
    // top-spacing to h1 if no intro text
        &:first-child {
            @media (@screen-medium) {
                margin-top: 40px;
            }
        }

        // disable colored bg variant
        &.row-all-colored {
            background: none;
            padding: 0;
        }
        // general limit to text width
        p {
            max-width: var(--width-soft-max);
        }
    }
    .program-list{
        max-width: var(--width-total);
        margin: 0 auto -60px auto;
        h2{
            margin-bottom: 10px;
        }
        ul{
            list-style: none;
            padding: 0;
            li{
                word-spacing: .2rem;
                font-size: 1.6rem;
                padding: 12px 0px 12px 0px;
                a{
                    flex-shrink: 100;
                    display: block;
                    margin-bottom: 8px;
                    font-size: 2.2rem;
                    font-weight: 600;
                    margin-right: 10px;
                    text-decoration: none;
                    word-spacing: normal;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &.the-frontpage {
        nav#breadcrumbs {
            display: none;
        }
        .grid-container {
            &:first-child {
                @media (@screen-medium) {
                    margin-top: 0;
                }
            }
        }
        .grid-container.semi-wide {
            padding-top: 0;
            padding-bottom: 0;
            .vrtx-frontpage-row.row {
                padding: 0;
            }
        }
        .vrtx-frontpage-box-picture {
            .vrtx-box-content {
                h2 {
                    margin: 20px 0;
                }
            }
        }


        .img-special-left, .img-special-right {
            .vrtx-frontpage-box-picture {
                position: relative;
                img {
                    display: block;
                    max-width: 100%;
                }
                .vrtx-box-content {
                    color: var(--color-light);
                    height: 100%;
                    align-items: start;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 10% 0 10% 10%;
                    h2 {
                        font-size: 5.4rem;
                        z-index: 1;
                        padding-right: 30px;
                    }
                    p {
                        font-size: 3.6rem;
                        line-height: 1.2;
                    }
                }
        //         &::before {
        //             display: none;
        //             @media (@screen-medium) {
        //                 display: block;
        //                 content: "";
        //                 position: absolute;
        //                 height: 33%;
        //                 width: calc(100vw * 0.15);
        //                 max-width: 14em;
        //                 right: 0px;
        //                 background: url('../images/decor-lines-red.svg') no-repeat;
        //                 background-size: contain;
        //             }
        //         }
        //         &::after {
        //             display: none;
        //             @media (@screen-medium) {
        //                 display: block;
        //                 content: "";
        //                 position: absolute;
        //                 bottom: -1.3em;
        //                 height: 33%;
        //                 width: calc(100vw * 0.15);
        //                 max-width: 14em;
        //                 left: 2.2em;
        //                 z-index: 1;
        //                 background: url('../images/decor-lines-white.svg') no-repeat;
        //                 background-size: contain;
        //             }
        //         }
           }
        }
    }
    .grid-container.library-search {
        background: var(--color-primary);
        padding: 80px 0;
        @media (@screen-below-medium) {
            background: none;
            padding: 0;
        }
        .vrtx-box-content {
            background: var(--color-light);
            padding: 40px 60px;
            max-width: var(--width-total);
            margin: 0 auto;
            @media (@screen-below-medium) {
                padding: 40px 0;
            }
            fieldset {
                display: flex;
                margin-bottom:  40px;
                input {
                    flex: auto;
                    width: 100%;
                }
            }
            .read-more {
                margin-right: 40px;
            }
        }
    }
}
#vrtx-frontpage.vrtx-editor-frontpage {
    .img-special-left, .img-special-right {
        &.red, &.grey {
            &.diagonal {
                .vrtx-box-content:after {
                    display: none;
                }
            }
        }
    }
}