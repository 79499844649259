// oversized (above --width-total-max)
@screen-oversized: ~"min-width: 1441px";
@screen-below-oversized: ~"max-width: 1440px";
// extra-large
@screen-xlarge: ~"min-width: 1041px";
@screen-below-xlarge: ~"max-width: var(--width-total)";
// large
@screen-large: ~"min-width: 1001px";
@screen-below-large: ~"max-width: 1000px";
// medium
@screen-medium: ~"min-width: 751px";
@screen-below-medium: ~"max-width: 750px";
// small
@screen-small: ~"min-width: 601px";
@screen-below-small: ~"max-width: 600px";


:root {
// viewport
--width-total-max: 1440px;
--width-total: 1040px;
--width-text-max: 690px;
// Colours
--color-light-tint: #F7E2E6; //pink
--background-active: #333333;
--background-inactive: #f0f0f0;
--gr: #999;
--lys-gr: #CCC;
// link colour
--color-link: #C60C30; // rød

// Main colours
--color-primary: #C60C30; // rød
--color-secondary: #0099CC;

// Neutral colours
--color-light: #ffffff;
--color-neutral--light: #f0f0f0;
--color-neutral: #999;
--color-dark: #333333;

--radius: 0;

--color-message: var(--color-secondary);
--icon-message: url("../images/message-icon.svg");
--img-alert: url("../images/warning-triangle.svg");
--color-crisis: #FCC033;
}