// NIH: studyprogram.less

// Removing context-title over h1
.vrtx-context-box.vrtx-context-box-linked {
    display: none;
}

body { // infomessage about program status
    &#vrtx-fs-studieprogram-frontpage, &#vrtx-fs-studieprogram-document {
        .vrtx-context-message-box.grid-container {
            margin-bottom: 0px;
            padding-bottom: 20px;
            .row {
                background-color: var(--color-message);
                max-width: 100%;
                margin: 0;
                padding: 0;
                .content-wrapper {
                    max-width: var(--width-total-max);
                    margin: 0 auto;
                    padding: 20px 50px;
                    .message-text {
                        background: var(--icon-message) no-repeat left center;
                        padding-left: 45px;
                    }
                }
            }
        }
    }
}

#vrtx-fs-studieprogram-frontpage {
    #breadcrumbs {
        margin-left: 0;
    }
    h1 {
        max-width: var(--width-total);
        margin: 0 auto;
    }
    #vrtx-fs-studieprogram-facts {
        margin: 40px auto;
    }
    .vrtx-subfolder-menu {
        background: transparent;
        column-width: unset;
    }
    #vrtx-fs-studieprogram-plans {
        margin-bottom: 120px;
    }
    #vrtx-fs-studieprogram-content {
        #vrtx-fs-studieprogram-intro-wrapper {
            padding-bottom: 30px;
            @media (@screen-large) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 80px;
                align-items: center;
            }
            .vrtx-introduction-image {
                display: revert;
                margin-bottom: 0;
                img {
                    aspect-ratio: 4/3;
                    object-fit: cover;
                    width: 100%;
                }
                .vrtx-imagetext {
                    display: revert;
                }
            }
            #vrtx-introduction-wrapper {
                    display: flex;
                    flex-direction: column;
                    margin-top: 40px;
                .vrtx-introduction {
                    margin-bottom: 0px;
                    @media (@screen-medium) {
                        width: 100%;
                        align-self: center;
                    }
                }
           }
        }
    }
    .studyprogram-frontpage-content-body {
        max-width: var(--width-total);
        margin: 0 auto;
    }
    .vrtx-article-body {
        max-width: var(--width-text-max);
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 30px;
        display: block;
        ul.navigation-links {
            display: grid;
            row-gap: 30px;
            padding-left: 0;
        }
    }
    #vrtx-fs-studieprogram-folders, #vrtx-fs-studieprogram-stories {
        max-width: var(--width-text-max);
        .vrtx-subfolder-menu ul, ul.items {
            grid-template-columns: 1fr;
        }
    }
    #vrtx-fs-studieprogram-folders {
        margin-top: 30px;
        margin-bottom: 110px;
    }
    #vrtx-fs-studieprogram-contact {
        max-width: var(--width-text-max);
    }
}
#vrtx-fs-studieprogram-document, #vrtx-fs-emne {
    #vrtx-main-content {
        .fs-body {
            max-width: var(--width-text-max);
        }
        .vrtx-fs-study-model {
            .course-list {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 40px;
                li {
                    position: relative;
                    border-top: 1px solid var(--color-primary);
                    padding: 0;
                    &:last-child{
                        border-bottom: 1px solid var(--color-primary);
                    }
                }
                li:not(.mandatory){
                    .course-code{
                        &:after{
                            content: "\00a0 / Valgbart emne";
                            display: inline-block;
                            color: var(--color-dark);
                        }
                    }
                    .course-study-points{
                        background: none;
                        color: var(--color-primary);
                        border-left: 1px solid;
                        border-right: 1px solid;
                    }
                }
                .course-description{
                    padding: 8px 90px 10px 10px;
                    display: block;
                    .course-code {
                        font-size: 1.6rem;
                        flex-shrink: 0;
                        color: var(--color-dark);
                        width: 72px;
                    }
                    .course-info {
                        display: block;
                    }
                    .course-not-linked-info {
                        display: none;
                    }
                    .course-terms {
                        order: 3;
                        color: var(--color-dark);
                        font-size: 1.6rem;
                        &:before{
                            content: "\00a0 /\00a0";
                        }
                    }
                }
                .course-study-points{
                    display: flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 80px;
                    color: #fff;
                    background: var(--color-primary);
                    span:first-child{
                        font-weight: 700;
                    }
                    span:first-child:after {
                        content: "\00a0";
                    }
                }
                .course-link {
                    text-decoration: none;
                    .course-description {
                        &:hover{
                            background-color: var(--color-neutral--light);
                            .course-info{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Studieretningsvelger
.vrtx-fs-study-model {
    form[id*="direction-form-"] {
        label {
            &::before {
                width: 18px;
                height: 18px;
                border: 2px solid var(--color-primary);
                box-shadow: none;
                border-radius: 0;
            }
        }
        input:focus + label {
            outline-color: var(--color-primary);
        }
        input:checked + label {
            background-color: var(--color-primary);
            &::before {
                width: 12px;
                height: 12px;
                border: 4px solid white;
                background: none;
            }
        }
    }
}