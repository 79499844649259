/* CSS for idrettshogskolen */

// x-styles
@import "../../../../../../../lib/x1/css/x-0.less";


// variables
@import 'variables.less';

// Libraries
@import "typography.less";
@import "utils.less";

// local imports
@import 'components.less';
@import 'listings.less';
@import 'frontpage.less';
@import 'event.less';
@import 'article.less';
@import 'studyprogram.less';
@import 'person.less';
@import 'project.less';


// Should be in x-styles
.row-all-colored.row-all-no-margin-bottom {
    margin-bottom: 0;
}
div#vrtx-collections {
    display: none;
}
body#vrtx-tagview .vrtx-resources .vrtx-resource {
    max-width: 290px;
}

.sidebar-menu-wrapper #supplementary-menu {
    display: block;
}

//local overrides
.vrtx-mode-preview #main {
  min-height: auto;
}

#breadcrumbs {
  margin-bottom: 40px;
  @media (min-width: 1141px) {
    margin-left: calc(calc(var(--width-total) + 100px - 100vw)/2);
  }
  @media (min-width: 1441px) {
    margin-left: calc(calc(calc(var(--width-total) + 100px - 100vw)/2 - calc(calc(var(--width-total-max) - 100vw)/2)));
  }

  span::after {
    content: "\002F"; // slash
    background: none;
    width: 0.7em;
    margin-left: 0.7em;
  }
}
html[lang=en] #header .logo {
  background: none;
}

#header {
    .logo {
        background: none;
        width: 257px;
        @media (@screen-below-medium) {
            width: 200px;
        }
    }
}

// language select
// on small screen, hide language select
// display it inside main menu when this is open
@media (@screen-below-medium) {
  #header-tools {
    grid-template-columns: auto auto;
    #header-language {
      // faux position inside menu
      position: absolute;
      right: 0;
      top: 107px;
      transition: all .5s;
    }
  }

  // hide lang select unless main menu is open
  body:not(.sidebar-menu-wrapper-visible) {
    #header-tools {
      #header-language {
        display: none;
      }
    }
  }

  // when search open, move lang select down
  body.header-search-visible {
    #header-tools {
      #header-language {
        margin-top: 100px;
      }
    }
  }
}

#header-tools #header-language {
  gap: .3em;
  > span {
    border: none;
  }
  // Separator. Placement depends on selected language
  &:has(.lang-secondary) {
    > span::after {
      content: "/";
      position: relative;
      left: .3em;
    }
  }
  &:has(.lang-primary) {
    > span::before {
      content: "/";
      position: relative;
      left: -.3em;
    }
  }
}

body:not(#vrtx-frontpage).archive {
  #main {
    max-width: var(--width-total);
    margin: 0 auto;
    #breadcrumbs {
      @media (min-width: 1141px) {
        margin-left: calc(calc(var(--width-total) - 100vw)/2);
      }
      @media (min-width: 1441px) {
        margin-left: calc(calc(calc(var(--width-total) - 100vw)/2 - calc(calc(var(--width-total-max) - 100vw)/2)));
      }
    }
    .row {
       padding: 0;
    }
    margin-bottom: 80px;
  }
}
#vrtx-fs-studieprogram-listing, #vrtx-article-listing, #vrtx-collection, #vrtx-event-listing, #vrtx-project-listing, #vrtx-fs-emne-listing, #vrtx-uh-person-listing {
  .vrtx-introduction {
    max-width: var(--width-total);
  }
  #total-main, #main #right-main {
      max-width: var(--width-total);
      margin: 0 auto;
  }
}

#vrtx-structured-article , #vrtx-structured-event{
    h1, .vrtx-introduction {
        max-width: var(--width-total);
    }
    #vrtx-content {
      margin-left: 0;
    }
}
#vrtx-structured-article, #vrtx-structured-project, #vrtx-structured-event, #vrtx-uh-person, #vrtx-fs-studieprogram-document, #vrtx-fs-emne, #vrtx-image-preview {
    #total-main, #main #right-main {
        max-width: var(--width-total);
        margin-left: auto;
        margin-right: auto;
    }
}
.vrtx-image-gallery-include-outer-wrapper {
  margin-left: -150px;
}

nav#left-menu-same-level-folders,
nav#global-sub-menu {
    display: none;
}

#footer-wrapper h2, #footer-wrapper .menu-label {
    font-weight: 600;
}

@media (max-width: 1300px) {
  .vrtx-image-gallery-include-outer-wrapper {
    margin-left: 0;
  }
}

.visit-table{
  font-size: 2.2rem;
  th{
    padding-left: 0px;
    padding-right: 20px;
    border-width: 1px 0px;
    font-weight: 400;
  }
  td{
    border-width: 1px 0px;
  }
}

.vrtx-feed-link {
  display: none;
}
@media print {
  #header-tools, #footer-wrapper {
    display: none;
  }
}