// NIH: components.less

#crisis-banner, #crisis-banner-demo {
    background-color: var(--color-crisis);
    a {
        color: black;
    }
    .crisis-banner-inner {
      color: black;
      width: fit-content;
      max-width: 100vw;
      margin: 0 auto;
      padding-left: 50px;
      position: relative;
      &::before {
        background: var(--img-alert) no-repeat center;
        width: 1.7em;
        height: 2em;
        top: -2px;
        left: 0px;
        position: absolute;
        background-size: contain;
      }
    }
  }

.sidebar-menu-wrapper .sidebar-global-menu {
    border: none;
    .vrtx-tab-menu  a {
        font-size: 2.7rem;
        @media (@screen-below-medium) {
            font-size: 2rem;
        }
    }
}

// Move column-* to ul in x-menus.less?
// Updated background 26. aug 2024 by Kjetil, due to changes in X
#main .vrtx-subfolder-menu {
    background-color: var(--color-neutral-tint-95);
    column-width: unset;
    column-count: unset;
    ul {
        column-width: 250px;
        column-count: auto;
        column-gap: 30px;
    }
}
#main .navigation-links .vrtx-subfolder-menu {
    background: none;
    a:hover {
        text-decoration: none;
    }
}

.vrtx-introduction {
    font-weight: 400;
    font-size: 2.7rem;
    @media (@screen-below-medium) {
        font-size: 2.4rem;
    }
}
#vrtx-fs-studieprogram-facts, #vrtx-fs-emne-facts {
    max-width: var(--width-total);
    margin: 40px 0;
    border-top: 1px solid var(--gr);
    border-bottom: 1px solid var(--gr);
    dl {
        display: flex;
        flex-wrap: wrap;
        row-gap: 18px;
        column-gap: 60px;
        margin-top: 25px;
        @media (@screen-below-small) {
            flex-direction: column;
        }
        .dg {
            display: flex;
            flex-wrap: nowrap;
            ul {
                display: flex;
                column-gap: 0.5em;
                padding-left: 0;
                li {
                    padding-top: 0;
                    &::marker {
                        color: transparent;
                    }
                    &::after {
                        content: "•";
                        padding-left: 0.5em;
                    }
                    &:last-child::after {
                        content: "";
                        padding-left: 0;
                    }
                }
            }
        }
    }
    h2 {
        display: none;
        font-size: 2.0rem;
        margin-bottom: 0;
    }
    dd, dt {
        display: inline;
        white-space: nowrap;
    }
    dt {
        font-weight: 600;
    }
    dd {
        margin-inline-start: 0.5em;
        &:after {
            content: "";
        }
    }
}
input {
    border-radius: 0;
    border: 1px solid var(--gr);
    padding: 20px;
    font-size: 2.2rem;
    line-height: 1.25;
    &[type=radio],
    &[type=checkbox] {
        padding: 0; // Or becomes huge in iOS
    }
}
//real button
#main button, #vrtx-searchview .vrtx-big-search button {
    background-color: var(--color-primary);
    border: 3px solid var(--color-primary);
    border-radius: 0;
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--color-light);
    padding: 17px 30px;
    line-height: 1.25;
    margin-left: 20px;
    white-space: nowrap;
    &:hover, &:focus {
        background-color: var(--color-light);
        border: 3px solid var(--color-primary);
        color: var(--color-primary);
    }
}

//not a button
.button {
    background-color: var(--color-light);
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--color-primary);
    border: 3px solid var(--color-primary);
    border-radius: 0;
    padding: 15px 62px 15px 21px;
    &::after {
        content: url('../images/arrow-red.svg');
        background: none;
        right: 0.8em;
        top: 0.7em;
        height: 1.7em;
        width: 1.2em;
    }
}
.button:hover::after, .button-loud:hover::after, #vrtx-fs-studieprogram-search-button:hover::after {
    right: 5px;
}
#vrtx-frontpage {
    .img-special-left, .img-special-right {
        &.red {
            .button {
                background-color: var(--color-primary);
                border: 3px solid var(--color-light);
                color: var(--color-light);
                &::after {
                    content: url('../images/arrow-white.svg');
                }
            }
            .vrtx-box-content a.button-loud {
                background-color: var(--color-light);
                border: 3px solid var(--color-primary);
                color: var(--color-primary);
                &::after {
                    content: url('../images/arrow-red.svg');
                }
            }
        }
    }
}
.button-loud, #vrtx-fs-studieprogram-search-button {
    background-color: var(--color-primary);
    font-weight: 600;
    color: var(--color-light);
    font-size: 2.2rem;
    display: inline-block;
    position: relative;
    padding: 18px 65px 18px 24px;
    text-decoration: none;
    border: none;
    border-radius: 0;
    &:hover, &:focus {
        background-color: var(--color-primary);
    }
    &::after {
        content: url('../images/arrow-white.svg');
        background: none;
        position: absolute;
        right: 0.8em;
        top: 0.9em;
        height: 1.7em;
        width: 1.2em;
        transition: all 0.3s;
    }
}
#vrtx-fs-studieprogram-search-button {
    width: fit-content;
    height: fit-content;
    a {
        font-weight: 600;
        color: var(--color-light);
        text-decoration: none;
    }
}

blockquote {
    margin: 0;
    padding-left: 62px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        height: 4em;
        width: 2.5em;
        left: 0;
        background: url('../images/blockquote.svg') no-repeat;
    }
    p:first-child {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 125%;
    }
}

.three-column-list, .two-column-list {
    .vrtx-box-content {
        ul,
        ol {
            column-gap: 40px;
            column-count: 1;
            li {
                break-inside: avoid;
            }
        }
    }
}
.three-column-list .vrtx-box-content {
    ul, ol {
        @media (@screen-small) {
            column-count: 3;
        }
    }
}
.two-column-list .vrtx-box-content {
    ul, ol {
        @media (@screen-small) {
            column-count: 2;
        }
    }
}

.people-three-column-list .vrtx-box-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (@screen-below-medium) {
        grid-template-columns: 1fr;
    }
}
.people-two-column-list .vrtx-box-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (@screen-below-medium) {
        grid-template-columns: 1fr;
    }
}

.vrtx-frontpage-box.box-special .read-more, .read-more, .vrtx-more a, .vrtx-read-more, .all-messages, .navigation-links li a {
    padding-right: 2em;
    font-size: 2.2rem;
    font-weight: 600;
    &::before {
        display: none;
    }
    &::after {
        content: "";
        background: url('../images/arrow-red.svg') no-repeat;
        height: 0.9em;
        width: 0.9em;
        font-size: 2.2rem;
        background-size: contain;
        position: absolute;
    }
    &:hover::after {
      text-decoration: none;
    }
}
.navigation-links li a {
    text-decoration: none;
    &:after {
        right: 1em;
        top: 1.1em;
    }
    &:hover:after {
        right: 0.6em;
    }
}
.read-more, .vrtx-more, .vrtx-read-more, .all-messages {
    width: fit-content;
    position: relative;
    &::after, a::after {
        top: 0.4em;
        transition: all 0.3s;
        right: 0.4em;
    }
    &:hover::after, a:hover::after {
        right: 0;
    }
    a:hover, a:focus, &:hover, &:focus {
        text-decoration: none;
    }
}

h2.read-more {
    a {
        padding-right: 0;
        text-decoration: none;
    }
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
#main {
    .row-all-colored .navigation-links,
    .navigation-links {
        ul {
            gap: 20px;
            li {
                padding-bottom: 0;
                p {
                    display: grid;
                }
                a {
                    border-radius: 0;
                    border: 0;
                    padding: 15px 60px 15px 24px;
                    background-color: var(--color-neutral--light);
                    border-bottom: 3px solid transparent;
                    color: var(--color-primary);
                    font-weight: 600;
                    font-size: 2.2rem;
                    //If text is set in strong, the other text should have weight 400 while strong should have 600
                    &:has(strong) {
                        font-weight: 400;
                        font-size: 1.8rem;
                    }
                    strong {
                        font-weight: 600;
                        font-size: 2.2rem;
                    }
                    &:hover {
                        border-color: var(--color-primary);
                    }
                }
            }
        }
    }
}
details.accordion-wrapper {
    border-bottom: 2px solid var(--color-neutral);
    margin-bottom: 0px;
    summary {
        margin-bottom: 0;
        width: 100%;
        h2.accordion {
            font-size: 2.8rem;
            margin: 0;
        }
        h3.accordion {
            font-size: 2.2rem;
        }
        h4.accordion {
            font-size: 1.8rem;
        }
        .accordion {
            width: auto;
            padding: 12px 1em 10px 0;
            margin-bottom: 0;
            &::before {
                display: none;
            }
            &::after {
                content: '+';
                right: 0;
                top: 0.5em;
                position: absolute;
            }
        }
        &:hover {
            text-decoration: none;
            text-decoration-color: transparent ;
        }
        p {
            padding-bottom: 20px;
        }
    }

    &[open] summary .accordion::after {
        content: '–';
        margin-top: -0.1em;
    }
}
.accordion-end {
    margin-top: 15px;
}

//contact component
.vrtx-contact-component {
    position: relative;
    padding-right: 20px; //Eats up space on mobile – Is it necessary?
    min-height: 190px;
    @media (@screen-below-medium) {
        min-height: 150px;
    }
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    a:not(.name a):not(.contact a) {
        position: absolute;
        left: 0;
        top: 0;
        img {
            width: 150px;
            height: auto;
            //min-height: 150px; img got squashed on mobile
            object-fit: cover;
            aspect-ratio: 1/1;
            border-radius: 50%;
            margin: 0;
            @media (@screen-below-medium) {
                width: 120px;
            }
        }
    }
    a:not(.name a)+.name,
    a:not(.name a)+.name+.title,
    a:not(.name a)+.name+.title+.contact,
    .addresses {
        margin-left: 150px;
        padding-left: 40px;
        @media (@screen-below-medium) {
            margin-left: 120px;
            padding-left: 20px;
        }
    }
    .name {
        margin-bottom: 8px;
        font-weight: 600;
    }
    .title,
    .contact {
        font-size: 1.6rem;
        display: grid;
        grid-gap: 4px 16px;
        grid-template-columns: max-content;
        dt {
            font-weight: 600;
        }
        dd {
            grid-column-start: 2;
        }
    }
} // .vrtx-contact-info

.contactcard-wrapper {
    display: flex;
    align-items: center;
    @media (@screen-below-medium) {
        flex-direction: column;
    }
}

// tags
.vrtx-tags {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin: 60px 0;
    @media (@screen-below-small) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
        margin-top: 0;
    }
    .title {
        font-weight: normal;
        white-space: no-wrap;
    }
    .vrtx-tags-links {
        display: flex;
        column-gap: 10px;
        flex-wrap: wrap;
        @media (@screen-below-small) {
            flex-direction: column;
            row-gap: 10px;
        }
        a {
            padding: 2px 8px;
            border-radius: 30px;
            border: 1px solid var(--lys-gr);
            text-decoration: none;
            font-size: 16px;
        }
        span.tag-separator {
            display: none;
        }
    }
}


#vrtx-structured-article, #vrtx-structured-event {        
    .vrtx-article-body .image-center {
        img, video {
            @media (min-width: 1001px) {
                margin-left: -50px;
            }
            @media (min-width: 1155px) {
                margin-left: calc(520px - 50vw);
            }
        }
    }
}
