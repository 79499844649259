// NIH: listings.less

#vrtx-collection {
    .vrtx-resources .vrtx-resource {
        margin-top: 0;
        margin-bottom: 0;
        padding: var(--space) 0;
        border-bottom: 1px solid var(--gr);
        &:first-of-type {
            border-top: 1px solid var(--gr);
        }
        .vrtx-title {
            margin: 0;
            a.vrtx-title {
                font-size: 1.8rem;
            }
        }
    }
}

// blog-listing
#vrtx-blog-listing #breadcrumbs {
    margin-left: 0;
}

// project-listing
#vrtx-project-listing {
    #main #right-main {
       >ul {
        padding-left: 0;
        font-size: 2.8rem;
           >li {

               &::marker {
                   color: transparent;
               }
           }
       }
       ul ul {
        font-size: 1.8rem;
           list-style-type: disc;
           li::marker {
               color: var(--color-primary);
           }
       }
    }

}

// reverting general padding on li
.vrtx-feed.with-images ul li {
    padding-bottom: 0;
}

// person-listing
#vrtx-uh-person-listing {
    legend.search-field-label {
        display: none;
    }
    label[for="vrtx-filter-query-value"] {
        flex: 1 100%;
    }
    #main #filters-form {
        #vrtx-filter-query {
            flex-wrap: wrap;
            input {
                flex: 1;
            }
        }
        #vrtx-filter-enhet {
            margin-top: 20px;
            .filter-name {
                font-weight: 600;
                margin-bottom: 20px;
                padding-left: 0;
            }
            ul.values {
                padding-left: 0;
                column-count: 2;
                @media (@screen-below-medium) {
                    column-count: 1;
                }
                li {
                    break-inside:avoid;
                    &::marker {
                        color: transparent;
                    }
                }
            }
        }
    }

    #main ul.active-filters {
        margin-top: 10px;
        li {
            padding: 0;
            &::marker {
                color: transparent;
            }
            .filter-remove {
                border: 1px solid transparent;
                border-radius: 100px;
                background: var(--color-light-tint);
              }
            a {
                &:hover, &:hover:after{
                    color: var(--color-dark);
                }
            }
        }
    }
    .active-filters {
        margin-top: 20px;
        display: block;
    }
    .vrtx-person-search-hits {
        margin-top: 40px;
    }
    ul.vrtx-person-listing {
        list-style: none;
        padding-left: 0;
        margin-bottom: 30px;
        li {
            position: relative;
            display: grid;
            border-top: 1px solid var(--gr);
            padding: 10px 0 15px;
            padding-left: 85px;
            min-height: 85px;
            &:last-child {
                border-bottom: 1px solid var(--gr);
            }
            // Image and placeholder
            img,
            &:not(:has(img))::before {
                position: absolute;
                left: 0;
                top: 10px;
                width: 65px;
                aspect-ratio: 1/1;
                border-radius: 100%;
            }
            &:not(:has(img))::before {
                content: "";
                background-color: var(--color-neutral--light);
            }
            img {
                object-fit: cover;
            }
            @media (@screen-medium) {
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 10px;
                padding-bottom: 10px;
                .vrtx-person-listing-name {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                }
                .vrtx-person-listing-position {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                }
                .vrtx-person-listing-phone {
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                }
                .vrtx-person-listing-mobilephone {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }
                .vrtx-person-listing-email {
                    grid-column: 3 / 4;
                    grid-row: 1 / 3;
                }
            }
        }
    }
}

// feed
.vrtx-feed {
    .item-title {
        font-weight: 600;
        font-size: 2.2rem;
        color: var(--color-dark);
    }
    .published-date {
        color: var(--color-dark);
        font-size: 1.8rem;
    }
    .item-description {
        margin-bottom: 20px;
    }
}
.vrtx-article-body {
    .vrtx-feed.with-images {
        figure, img, video {
          margin: 0;
        }
    }
}
.vrtx-image  img {
    aspect-ratio: 16/9;
}

// article-listing
#vrtx-article-listing {
    .vrtx-resources {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 30px;
        @media (@screen-below-medium) {
            grid-template-columns: 1fr 1fr;
        }
        @media (@screen-below-small) {
            grid-template-columns: 1fr;
        }
        .vrtx-resource {
            flex-direction: column;
            row-gap: 0px;
            .vrtx-image {
                max-width: 100%;
                flex-basis: auto;
            }
            .vrtx-list-item-content {
                flex-basis: unset;
                .vrtx-title {
                    font-size: 2.2rem;
                    margin-bottom: 20px;
                    font-weight: 600;
                }
            }
        }
    }
}

// event
.vrtx-event-component-title.summary {
    font-weight: 600;
    font-size: 2.8rem;
    color: var(--color-dark);
}

.vrtx-event-component-item {
    background-color: var(--color-neutral--light);
    padding: 20px 20px 5px 20px;
    .vrtx-date-icon {
        background-color: var(--color-dark);
        color: var(--color-light);
        font-size: 2.2rem;
        padding: 8px 12px;
        .vrtx-date-icon-day, .vrtx-date-icon-month {
            display: unset;
            font-weight: normal;
        }
        .vrtx-date-icon-month {
            font-size: inherit;
        }
        &:has(.vrtx-date-icon-today) .vrtx-date-icon-month {
            display: none;
        }
    }
    .vrtx-date-icon + .vrtx-event-component-main .vrtx-event-component-title.summary {
        font-size: 2.2rem;
    }
}

// event-listing
#vrtx-event-listing {
    .vrtx-resources .vrtx-resource.vevent {
        background: var(--color-neutral--light);
        padding: 40px 40px 22px 38px;
        margin-bottom: 40px;
        .vrtx-list-item-content {
            flex-basis: 100%;
            .vrtx-title a {
                text-decoration: underline;
                font-size: 2.8rem;
                font-weight: 600;
                color: var(--color-dark);
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

.vrtx-time-and-place-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
    margin-bottom: 15px;
    color: var(--color-primary);
    font-size: 2.1rem;
    span.time-and-place {
        display: none;
    }
    time.dtstart,
    .location {
        display: flex;
        align-items: center;

        &::before {
            content: "";
            height: 1.6em;
            width: 1.6em;
        }
    }

    time.dtstart::before {
        background: url('../images/icon-calendar-today.svg') no-repeat 0 0;
        margin-right: 10px;
    }

    .location::before {
        background: url('../images/icon-location-pin.svg') no-repeat 0 0;
        margin-right: 10px;
    }
}
