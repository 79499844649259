// NIH: typography.less
@main-font: 'Open Sans', sans-serif;

body {
    font-family: @main-font;
    font-weight: 400;
    color: var(--color-dark);
}
h1, h2, h3, h4, h5, h6, .vrtx-toc-header {
  font-weight: 600;
  strong {
    font-weight: 600;
  }
}
h1 {
  color: var(--color-primary);
  font-size: 5.4rem;
}

h3 {
  font-size: 2.8rem;
}

h4, h5 {
  font-size: 2.2rem;
}

h4, h5, h6 {
  margin-bottom: 0.4em;
}
@media (@screen-below-medium) {
  h1 {
    font-size: 3.0rem;
  }
  h2 {
    font-size: 2.8rem;
  }
  h3 {
    font-size: 2.2rem;
  }
  h4, h5, h6 {
    font-size: 1.8rem;
  }
}

#main {
  ul, ol {
    margin-bottom: 13px;
    li {
      padding-top: 12px;
      &::marker {
        color: var(--color-primary);
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
#main .vrtx-tabs > ul > li {
  padding-bottom: 0;
}

a {
  color: var(--color-link);
  text-underline-offset: 0.1em;
  text-decoration-thickness: 0.07em;
}
#left-menu-same-level-folders ul .vrtx-marked {
  color: var(--color-link);
}
#header-tools #header-language {
  font-weight: 600;
  > a, > span {
    font-size: 1.6rem;
  }
}
#header-tools #header-language > a {
  color: var(--color-dark);
  text-decoration: underline;
}
#breadcrumbs a {
  color: var(--color-dark);
  text-decoration: none;
}
#header-tools .toggle-search, #header-tools .sidebar-menu-toggle {
  font-size: 1.6rem;
  font-weight: 600;
}
#footers {
  a {
    color: var(--color-light);
  }
  a.vrtx-login-manage-edit, a.vrtx-login-manage-admin {
    color: black;
  }
}

 /* Open Sans by Steve Mattheson
 Licence: https://github.com/googlefonts/opensans/blob/main/OFL.txt
 */
  /* latin regular-400-italic */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-regular-400-italic.woff2') format('woff2');
  }

  /* latin medium-500-italic */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-medium-500-italic.woff2') format('woff2');
   }

  /* latin semibold-600-italic*/
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-semibold-600-italic.woff2') format('woff2');
    }

  /* latin bold-700-italic*/
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-bold-700-italic.woff2') format('woff2');
    }

  /* latin regular-400 */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-regular-400.woff2') format('woff2');
    }

  /* latin medium-500 */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-medium-500.woff2') format('woff2');
  }

  /* latin semibold-600 */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-semibold-600.woff2') format('woff2');
  }

  /* latin bold-700*/
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url('../fonts/opensans/opensans-bold-700.woff2') format('woff2');
  }
