// NIH: project.less

#vrtx-structured-project {
    #main {
        margin-bottom: 0;
        #vrtx-content {
            display: block;
            p.sub-title {
                color: var(--color-primary);
                font-weight: 600;
                font-size: 2.2rem;
                margin-bottom: 40px;
            }
            .vrtx-uh-project-status {
                display: none;
            }
            .time-and-place-columns {
                padding: 26px 0 20px 0;
                margin-bottom: 40px;
                border-top: 1px solid var(--gr);
                border-bottom: 1px solid var(--gr);
                display: flex;
                column-gap: 80px;
                row-gap: 40px;
                max-width: var(--width-total);
                width: fit-content;
                @media (@screen-below-medium) {
                    flex-wrap: wrap;
                }
                div {
                    display: flex;
                    flex-direction: column;
                    dd,
                    dt {
                        display: inline;
                    }
                    dt {
                        font-weight: 700;
                        margin-bottom: 10px;
                        -webkit-hyphens:manual;
                        hyphens: manual;
                        overflow-wrap: initial;
                    }
                    dd {
                        margin-inline-start: 0;
                    }
                }
            }

            .vrtx-article-body {
                max-width: var(--width-text-max);
                border-bottom: none;
                padding-bottom: 10px;
                margin-bottom: 10px
            }

            div#vrtx-publications-wrapper {
                margin-bottom: 60px;
                width: var(--width-text-max);
                max-width: 100%;
            }


            .vrtx-grey-lower-banner {
                background-color: var(--color-neutral--light);
                max-width: none;
                width: 100vw;
                margin-left: calc(50% - 50vw);
                padding-left: calc(50vw - 50%);
                padding-right: 50px;
                padding-top: 40px;
                padding-bottom: 120px;
                @media (@screen-below-medium) {
                    padding-right: 15px;
                }
                .vrtx-project-owner,
                .vrtx-project-affiliations,
                .vrtx-contact-info,
                details {
                    width: 100%;
                    max-width: var(--width-text-max);
                    @media (@screen-below-xlarge) {
                        margin-left: 0;
                    }
                }
                .vrtx-project-owner,
                .vrtx-project-affiliations {
                    margin-bottom: 60px;
                }
            } // .vrtx-grey-lower-banner
            .vrtx-date-info {
                display: none;
            }
        } // #vrtx-content
    }
    #left-menu-same-level-folders,
    #global-sub-menu {
        display: none;
    }
} // #vrtx-structured-project