// NIH: event.less

#vrtx-structured-event {
    div#left-main {
        display: none;
    }
    .vrtx-introduction-image {
        max-width: 1041px;
    }
    .vevent {
        color: var(--color-primary);

        .vrtx-time-and-place-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 5px;
            .time-and-place {
                display: none;
            }

            time.dtstart, .location {
                font-weight: 600;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    height: 1.6em;
                    width: 2em;
                    margin-right: 8px;
                }
            }
            time.dtend {
                font-weight: 600;
                margin-right: var(--space);
            }
            time::before {
                background: url('../images/icon-calendar-today.svg') no-repeat 0 0;
            }
            .separator-time-and-place {
                display: none;
            }
            .location::before {
                background: url('../images/icon-location-pin.svg') no-repeat 0 0;
                /*margin-left: 70px; add margin right to dtend to better support responsivity*/
            }
            .vrtx-number-of-comments-add-event-container {
                flex-basis: 100%;
                margin-left: 50px;
            }
        }
    }
}
